import { render } from "./Index.vue?vue&type=template&id=5c4da211&scoped=true"
import script from "./Index.vue?vue&type=script&lang=ts"
export * from "./Index.vue?vue&type=script&lang=ts"

import "./Index.vue?vue&type=style&index=0&id=5c4da211&lang=css"
import "./Index.vue?vue&type=style&index=1&id=5c4da211&lang=scss&scoped=true"
import "./Index.vue?vue&type=style&index=2&id=5c4da211&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-5c4da211"

export default script